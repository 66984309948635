import React, { useState, useContext, Fragment, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import classNames from "classnames"
import axios from "axios"

import Layout from "layout"
import Container from "layout/Container"
import Section from "elements/Section"
import Message from "elements/Message"
import ActionButtons from "elements/ActionButtons"

import { useSession } from "services/hooks/useSession"
import { formSchema } from "../../../MedEnsure/Request/utils/formSchema"
import { generateFormField } from "../../../Elements/Form/services/form"
import { parseTermsAndConditions } from "../../services/parseTermsAndConditions"
import { doctorSmsContent } from "../../utils/templates/doctorEnrollmentSmsContent"
import { DoctorEnrollmentContext } from "../../EnrollmentContext/DoctorEnrollmentContext"
import { doctorEnrollmentTicketBody } from "../../utils/templates/doctorEnrollmentZendeskTemplate"
import {
  zendeskApi,
  generateRequestTemplate,
} from "../../../../services/zendeskService"

import styles from "../../utils/enrollment.module.scss"

import {
  GATSBY_SUBMIT_DOCTOR_ENROLLMENT_WEBHOOK,
  GATSBY_ZENDESK_EMAIL,
  GATSBY_ZENDESK_API_KEY,
  GATSBY_ZENDESK_SUBDOMAIN,
  GATSBY_TELERIVET_URL,
  GATSBY_TELERIVET_API_KEY,
  GATSBY_TELERIVET_ROUTE_ID,
  GATSBY_TELERIVET_PROJECT_ID,
  GATSBY_ENV,
} from "gatsby-env-variables"

let { parseFormField } = require("../../../../services/airtable")

const DoctorEnrollmentForm = ({ pageContext }) => {
  const [loading, setLoading] = useState(false)
  // const [formValues, setFormValues] = useState({})
  const { module, pageContent } = pageContext
  const { doctorEnrollmentState } = useContext(DoctorEnrollmentContext)
  const {
    formValues,
    isFormValuesEmpty,
    initializeFormValues,
    handleFormChange,
    clearFormValues,
  } = useSession("doctorEnrollment")

  let termsAndConditions = pageContent[0]?.body?.childMarkdownRemark?.html
  let parsedTerms = parseTermsAndConditions(termsAndConditions)
  let formFields = pageContext.formFields
  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )
  let mgcareZendeskConfig = {
    email: GATSBY_ZENDESK_EMAIL,
    apiKey: GATSBY_ZENDESK_API_KEY,
    apiUrl: GATSBY_ZENDESK_SUBDOMAIN,
  }

  useEffect(() => {
    initializeFormValues()
    // eslint-disable-next-line
  }, [])

  const handleSuccessCallback = () => {
    navigate(pageContext.nextPath)
    setLoading(false)
  }

  const handleErrorCallback = () => {
    navigate(pageContext.nextPath)
    setLoading(false)
  }

  const handleSubmit = async (values) => {
    let tempDoctorState = {
      ...doctorEnrollmentState,
      ...values,
      terms: parsedTerms,
    }

    // Comment this out if you want to continue testing out form submissions
    clearFormValues()

    try {
      setLoading(true)

      const { firstName, lastName, email, mobileNumber } = tempDoctorState
      let zendeskTags = ["doctor_enrollment", "pulsecare", GATSBY_ENV]
      if (GATSBY_ENV !== "production") zendeskTags = [...zendeskTags, "test"]

      // Create a new Zendesk ticket
      const requestTemplate = generateRequestTemplate({
        subject: `PCP Doctor Signup from ${firstName} ${lastName}`,
        email: email,
        template: doctorEnrollmentTicketBody,
        templateObjects: tempDoctorState,
        tags: zendeskTags,
      })

      const zendeskResponse = await zendeskApi({ ...mgcareZendeskConfig }).post(
        "/requests.json",
        requestTemplate
      )

      const generatedZendeskId = zendeskResponse?.data?.request?.id

      // Send an SMS via Telerivet
      const telerivetMessageBody = {
        content: doctorSmsContent(generatedZendeskId),
        to_number: `+63${mobileNumber}`,
        route_id: GATSBY_TELERIVET_ROUTE_ID,
      }

      await axios.post(
        GATSBY_TELERIVET_URL,
        { ...telerivetMessageBody },
        {
          headers: {
            "Content-Type": "application/json",
            api_key: GATSBY_TELERIVET_API_KEY,
            route_id: GATSBY_TELERIVET_ROUTE_ID,
            project_id: GATSBY_TELERIVET_PROJECT_ID,
          },
        }
      )

      handleSuccessCallback()
    } catch (error) {
      await axios.post(GATSBY_SUBMIT_DOCTOR_ENROLLMENT_WEBHOOK, {
        ...tempDoctorState,
      })

      handleErrorCallback()
    }
  }

  return (
    <Layout {...module} pageContext={pageContext}>
      <Container isCentered>
        <Formik
          initialValues={
            isFormValuesEmpty ? { ...doctorEnrollmentState } : { ...formValues }
          }
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form onChange={(event) => handleFormChange(values, event)}>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  className="mt-1 mb-3"
                  isSectionRequired={section.isSectionRequired}
                >
                  {section?.message && (
                    <Message color={section?.messageColor || "light"}>
                      {section?.message}
                    </Message>
                  )}{" "}
                  {section?.section === "Consent and Authorization" && (
                    <Fragment>
                      <div
                        className={classNames(
                          "message-body my-2 is-size-4",
                          styles["collapsible"]
                        )}
                      >
                        <div className="content">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: termsAndConditions,
                            }}
                          />
                        </div>
                      </div>
                    </Fragment>
                  )}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: field,
                            values,
                            setFieldValue,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }

                    return null
                  })}
                </Section>
              ))}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{ label: "Back", link: pageContext.backPath }}
                submit={{
                  label: "Submit",
                  loading: loading,
                  disabled: !values.doctorConsentAndAuthorization.length,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default DoctorEnrollmentForm
